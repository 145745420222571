<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <!-- v-data-table  -->
    <v-row>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Search by Month & Year"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            :max="max"
            @input="$refs.menu.save(date)"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="panelist_ids"
          label="Enter Panelist Ids Comma Seperated"
        ></v-text-field>
      </v-col>
      <v-col cols="4" style="float: right">
        <v-btn small color="primary" class="mt-5" @click="getReport()">
          Filter
        </v-btn>
        <v-btn small color="default" @click="reset()" class="mt-5">
          Reset
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="margin-top: 0px">
      <v-col cols="12">
        <v-btn
          small
          :color="selectedPanelists.length > 0 ? 'primary' : 'secondry'"
          style="float: right"
          class="custom-button"
          @click="resetBags()"
        >
          Reset bags
        </v-btn>
        <v-btn
          small
          color="primary"
          style="float: right"
          @click="toggleUpdateModal = true"
        >
          Import
        </v-btn>
        <v-btn
          small
          color="primary"
          style="float: right"
          @click="updateDirectMail"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="tableWrapper">
          <div id="select-all-checkbox">
            <v-checkbox
              ref="n"
              v-model="allSelected"
              @click="selectAll"
            ></v-checkbox>
          </div>
          <v-data-table
            :headers="headers"
            item-key="product_id"
            :page.sync="page"
            :items="affinity_data"
            :options.sync="options"
            :server-items-length="totalAffinity"
            :loading="isLoading"
            class="elevation-1"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:item.selectCheckbox="{ item }">
              <v-checkbox
                v-model="selectedPanelists"
                :value="item.competi_id"
                :key="item.competi_id"
                @click="addToSubmit($event, item.competi_id)"
              ></v-checkbox>
            </template>
            <template v-slot:item.custom_direct_mail_point="{ item }">
              <input
                type="number"
                :value="item.direct_mail_point"
                @click="checkPanelist($event, item.competi_id)"
                @change="updatePanelistData($event, item, 'dm')"
              />
            </template>
            <template v-slot:item.custom_remaining_bags="{ item }">
              <select
                id=""
                style="width: 50px"
                @change="updatePanelistData($event, item, 'bags')"
                @click="checkPanelist($event, item.competi_id)"
              >
                <option
                  value="0"
                  :selected="item.remaining_bags == 0 ? 'selected' : ''"
                >
                  0
                </option>
                <option
                  value="1"
                  :selected="item.remaining_bags == 1 ? 'selected' : ''"
                >
                  1
                </option>
                <option
                  value="2"
                  :selected="item.remaining_bags == 2 ? 'selected' : ''"
                >
                  2
                </option>
                <option
                  value="3"
                  :selected="item.remaining_bags == 3 ? 'selected' : ''"
                >
                  3
                </option>
                <option
                  value="4"
                  :selected="item.remaining_bags == 4 ? 'selected' : ''"
                >
                  4
                </option>
              </select>
              <!-- <input type="number" step="1" min="0" max="4"
                @click="checkPanelist($event, item.competi_id)"
                :value="item.remaining_bags"
                    @change="updatePanelistData($event, item, 'bags')"> -->
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                medium
                color="grey"
                v-show="checkIfOperationExistForModule('edit')"
                @click="addToUpdate($event, item.affinity_id)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.dates="{ item }">
              <p>{{ date }}</p>
            </template>
          </v-data-table>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage"
              :getLastPageClass="getLastPageClass"
              :totPage="totPage"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>

          <div></div>
        </div>
      </v-col>
    </v-row>
    <!-- v-data-table-ends -->
    <v-row justify="center">
      <v-dialog
        v-model="toggleUpdateModal"
        persistent
        transition="dialog-bottom-transition"
        max-width="700"
      >
        <template>
          <v-card>
            <v-card-title>
              <span class="headline">Import Bags & Direct Mail Data</span>
            </v-card-title>
            <v-container class="px-50" fluid>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-file-input
                    label="File input*"
                    outlined
                    dense
                    accept=".csv"
                    v-model="importFile"
                  >
                  </v-file-input>
                  <p>
                    “Upload CSV with one entry per row and fields in order:
                    Panelist ID, Direct Mail Point, Bags Sent, Month, Year”
                  </p>
                </v-col>
              </v-row>
              <div style="color: red">
                {{ upmessage1 }}
              </div>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="updateImportData">
                Update
              </v-btn>
              <v-btn
                class="close_button mx-2"
                text
                @click="toggleUpdateModal = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading, Pagination },
  name: "PanelistReport",
  data() {
    return {
      toggleUpdateModal: false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      affinity_data: [],
      totalAffinity: 0,
      importFile: null,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Panelist ID",
          value: "competi_id",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Direct Mail Point",
          value: "custom_direct_mail_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Bags Remaining",
          value: "custom_remaining_bags",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Date",
          value: "dates",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      categories: [],
      searchCategories: [],
      query: "",
      categ: "",
      category: "",
      selectedPanelists: [],
      checked_affinityid: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      productCount: [],
      affinityIds: [],
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      affinityNames: [],
      addAffinity: {
        affinityName: null,
        category: null,
        subCategory: null,
      },
      updateAffinity: {
        affinityId: null,
        affinityName: null,
        category: null,
        subCategory: null,
      },
      subCategory: [],
      subcatName: [],
      subcategories: [],
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      showPerPage: false,
      date: new Date().toISOString().substr(0, 7),
      max: moment(new Date()).format("YYYY-MM-DD"),
      menu: false,
      modal: false,
      panelist_ids: "",
      update_bags: [],
      update_direct_mail: [],
      panelists_data: [],
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
    fromDate: function () {
      let date = this.date;
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      return year + "-" + month + "-01";
    },
    toDate: function () {
      let date = moment(this.fromDate).add(1, "M").format("YYYY-MM-DD");
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      //let days = this.getMonthDays(month,year)
      return year + "-" + month + "-01";
    },
    panelist_ids_arr: function () {
      if (this.panelist_ids == "") {
        return [];
      } else {
        let arr = this.panelist_ids.split(",");
        let new_arr = [];
        arr.forEach((id) => {
          new_arr.push(id.trim());
        });
        return new_arr;
      }
    },
    ///////
  },
  methods: {
    checkPanelist(e, competi_id) {
      if (!this.selectedPanelists.includes(competi_id)) {
        this.selectedPanelists.push(competi_id);
        this.addToSubmit(e, competi_id);
      }
    },
    addToSubmit(e, competi_id) {
      let view = this;
      console.log("hello" + e.target.checked);
      view.affinity_data.forEach(function (o, i) {
        if (o.competi_id == competi_id) {
          console.log("selected" + competi_id);
          if (view.selectedPanelists.includes(competi_id)) {
            view.panelists_data.push({
              competi_id: o.competi_id,
              direct_mail: o.direct_mail_point,
              bags: o.remaining_bags,
            });
          } else {
            view.panelists_data = view.panelists_data.filter(
              (o, i) => o.competi_id != competi_id
            );
          }
        }
      });
    },
    updatePanelistData(e, item, type) {
      console.log(e.target.value);
      let view = this;
      this.panelists_data.forEach((o, i) => {
        if (o.competi_id == item.competi_id) {
          if (type == "dm") {
            o.direct_mail = parseFloat(e.target.value);
          } else {
            o.bags = parseInt(e.target.value);
          }
        }
      });
      view.affinity_data.forEach(function (o, i) {
        if (o.competi_id == item.competi_id) {
          console.log("selected" + item.competi_id);
          if (type == "dm") {
            o.direct_mail_point = parseFloat(e.target.value);
          } else {
            o.remaining_bags = parseInt(e.target.value);
          }
        }
      });
    },
    //pagination methods
    updateDirectMail() {
      let check = this;
      let bags_dm_update_url = check.$url("BAGS_DIRECTMAIL_UPDATE");
      let data = {
        key: "direct_mail_bags_update",
        panelist_data: this.panelists_data,
        date: this.fromDate,
      };
      if (check.panelists_data.length) {
        check.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will update DM point and bags.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, update !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              check.isLoading = true;
              this.$fetch({
                requiresAuth: true,
                operation: "add",
                vueScope: check,
              })
                .post(bags_dm_update_url, data)
                .then((res, status, xhr) => {
                  check.isLoading = false;
                  console.log(res.data);
                  if (res.data.status == 200) {
                    check.panelists_data = [];
                    check.selectedPanelists = [];
                    this.$swal.fire({
                      icon: "success",
                      title: "Updated successfully",
                      text: res.data.message,
                    });
                    check.getReport(1);
                  } else {
                    this.$swal.fire({
                      icon: "error",
                      title: "Error",
                      text: res.data.message,
                    });
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select atleast one panelist to update!",
        });
      }
    },
    resetBags() {
      let check = this;
      let bags_dm_update_url = check.$url("BAGS_DIRECTMAIL_UPDATE");
      let data = {
        key: "reset_bag",
        panelist_data: this.panelists_data,
        date: this.fromDate,
      };
      if (check.panelists_data.length) {
        check.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will reset bags remaining.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, Reset !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              check.isLoading = true;
              this.$fetch({
                requiresAuth: true,
                operation: "add",
                vueScope: check,
              })
                .post(bags_dm_update_url, data)
                .then((res, status, xhr) => {
                  check.isLoading = false;
                  console.log(res.data);
                  if (res.data.status == 200) {
                    this.$swal.fire({
                      icon: "success",
                      title: "Bags Reset successfully",
                      text: res.data.message,
                    });
                    check.getReport(1);
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select atleast one panelist to reset bags!",
        });
      }
    },
    updateImportData() {
      let check = this;
      let producer_panelist_report_url = check.$url("BAGS_DIRECTMAIL_IMPORT");
      check.isLoading = true;
      // let data = {
      //     file : this.importFile,
      // }
      let formData = new FormData();
      formData.append("file", this.importFile);
      if (this.importFile != null) {
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: check })
          .post(producer_panelist_report_url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res, status, xhr) => {
            check.isLoading = false;
            console.log(res.data);
            if (res.data.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Updated successfully",
                text: res.data.message,
              });
              check.getReport(1);
            } else {
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    exportReport() {
      let check = this;
      let producer_panelist_report_url = check.$url(
        "DOWNLOAD_PRODUCER_PANELIST_REPORT"
      );
      check.isLoading = true;
      let data = {
        from_date: this.fromDate,
        to_date: this.toDate,
        type: "prod_panelist",
      };
      this.$fetch({ requiresAuth: true, operation: "export", vueScope: check })
        .post(producer_panelist_report_url, data)
        .then((res, status, xhr) => {
          check.isLoading = false;
          localStorage.setItem("temp", res.data);
          //console.log(res['data']);
          console.log(JSON.parse(res.data));
          let download_res = JSON.parse(res.data);
          let values_arr = [];
          download_res.forEach((o) => {
            let temp_obj = [];
            temp_obj.push(o.competi_id);
            temp_obj.push(o.first_name);
            temp_obj.push(o.address);
            temp_obj.push(o.city);
            temp_obj.push(o.country);
            temp_obj.push(o.direct_mail_point);
            temp_obj.push(o.email);
            temp_obj.push(o.email_pieces);
            temp_obj.push(o.email_point);
            temp_obj.push(o.incentive_value);
            temp_obj.push(o.panelist_id);
            temp_obj.push(o.remaining_bags);
            temp_obj.push(o.state);
            values_arr.push(temp_obj);
          });
          let csvContent =
            "data:text/csv;charset=utf-8," +
            values_arr.map((e) => e.join(",")).join("\n");
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "panelist_report.csv");
          document.body.appendChild(link); // Required for FF

          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getMonthDays(month, year) {
      if (month == "01") {
        return 31;
      } else if (month == "02" && year % 4 == 0) {
        return 29;
      } else if (month == "02" && year % 4 != 0) {
        return 28;
      } else if (month == "03") {
        return 31;
      } else if (month == "04") {
        return 30;
      } else if (month == "05") {
        return 31;
      } else if (month == "06") {
        return 30;
      } else if (month == "07") {
        return 31;
      } else if (month == "08") {
        return 31;
      } else if (month == "09") {
        return 30;
      } else if (month == "10") {
        return 31;
      } else if (month == "11") {
        return 30;
      } else if (month == "12") {
        return 31;
      }
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    /////
    resetSearch() {
      if (this.query != null || this.categ != "") {
        (this.page = 1), (this.query = ""), (this.categ = "");
        this.getReport();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.page = 1;
      this.date = new Date().toISOString().substr(0, 7);
      this.panelist_ids = "";
      this.getReport();
    },
    getReport(page = "", query = "", categ = "") {
      let check = this;
      let producer_panelist_report_url = check.$url("PRODUCER_PANELIST_REPORT");
      check.isLoading = true;
      let data = {
        from_date: this.fromDate,
        to_date: this.toDate,
        type: "prod_panelist",
        start_index: this.startIndex,
        end_index: this.endIndex,
        panelist_list: this.panelist_ids_arr,
      };
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(producer_panelist_report_url, data)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data);
          res.data.report = JSON.parse(res.data.report);
          if (res.data.report.length) {
            let result_data = [];
            res.data.report.forEach(function (item) {
              result_data.push(item);
            });
            this.affinity_data = result_data;
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;
            if (this.totRecords <= this.perpage) {
              this.page = 1;
            }
          } else {
            this.affinity_data = [];
            this.totalAffinity = res.data.report_length;
            this.totRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    selectAll: function () {
      let delaffinity = this.affinity_data;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.selectedPanelists = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {
            this.selectedPanelists.push(delaffinity[i].competi_id);
          }
        }
      } else if (this.allSelected == false) {
        this.selectedPanelists = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchAffinity: function () {
      this.getReport(this.page, this.query);
      console.log(this.query);
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getReport(1);
      }
    },
    page: function (ob) {
      this.allSelected = false;
      this.selectedPanelists = [];
      //this.getReport(ob, this.query);
    },
  },
};
</script>
