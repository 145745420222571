const baseUrl = 'http://localhost:5011/'
const baseUrlProd = 'https://master-uat-api.competiscan.com/'
const baseUrlUser = 'http://localhost:5056/'
const baseUrlUserProd = "https://users-uat-api.competiscan.com/"
const temprouct_url = "https://dev06.competiscan.com:5053/"
const temprouct_url_prod = "https://product-uat-api.competiscan.com/"
const admin_report_url = "https://api1-uat.competiscan.com/admin-report/v1/"
const admin_report_url_prod = "https://api1-uat.competiscan.com/admin-report/v1/"
const baseUrlSearch = 'http://0.0.0.0:5444/'
const baseUrlSearchProd = 'https://api1-uat.competiscan.com/search/v1/'

const companu_url = "searchcompanydata"
const state_url = "statstatesearche"
const get_file_uploads = "file-uploads"
const manage_reports = "manage-reports"
const download_file_uploads = "download-file-upload"
const user_url = "allusers"
const addproduct_form_url = "https://cs.uat.competiscan.com/addproduct/"
const viewproduct_form_url = "https://cs.uat.competiscan.com/viewproduct/"
const editprouct_url = "https://cs.uat.competiscan.com/product/"
const signInUrl = 'http://localhost:8080/'
const signInProdUrl = 'https://login.uat.competiscan.com/'
const rolePermissionUrl = 'http://localhost:5001/userRolesAndPermissions'
const rolePermissionProdUrl = 'https://users-uat-api.competiscan.com/userRolesAndPermissions'
const product_api_end = "https://product-uat-api.competiscan.com/"
const product_api_end_prod = "https://product-uat-api.competiscan.com/"
const frontend_url = "https://dev01.competiscan.com/"
const frontend_url_prod = "https://cs.uat.competiscan.com/"

const staging_contentsite_search = 'https://api1-uat.competiscan.com/search/v1/'

const file_upload_url = "https://dev06.competiscan.com:5418/fileupload"
const file_upload_url1 = "https://dev06.competiscan.com:5012/file-uploads"
const file_upload_url_prod = "https://dev06.competiscan.com:5418/fileupload"
const file_upload_url_prod1 = "https://dev06.competiscan.com:5012/file-uploads"

const baseUrl_annotation_tool_prod = "https://vat.uat.competiscan.com/display/"
const baseUrl_annotation_tool = "https://ml-anotation.staging.competiscan.com/v3/muid-data/"

const easy_ocr_baseurl = "https://ocr.competiscan.com/easyocrtextv1/"
const easy_ocr_baseurl_prod = "https://ocr.competiscan.com/easyocrtextv1/"
const csv2_product_bucket_path = "csv2-productcontent"
const csv2_product_bucket_path_prod  = "csv2-productcontent"
const addproduct_form_urlv2 = "https://cs.uat.competiscan.com/addproduct/v2/"
const merge_base_url = "https://api1-uat.competiscan.com/product-utility/v1/"
const trend_report_url = "https://api1-uat.competiscan.com/trendreport/v1/"

module.exports = {
    DEBUG: false,
    baseurl: baseUrl,
    baseurl_prod: baseUrlProd,
    admin_report_url_prod : admin_report_url_prod,
    admin_report_url : admin_report_url,
    temprouct_url: temprouct_url + "tempproduct",
    temprouct_prod_url: temprouct_url_prod + "tempproduct",
    direct_mail_temp_url: temprouct_url + "directmailtemp",
    direct_mail_temp_url_prod_url: temprouct_url_prod + "directmailtemp",
    direct_mail_processproduct_url: temprouct_url + "directmailprocess",
    direct_mail_processproduct_url_prod_url: temprouct_url_prod + "directmailprocess",
    company_url: baseUrl + "" + companu_url,
    company_url_prod: baseUrlProd + "" + companu_url,
    state_url_prod: baseUrlProd + "" + state_url,
    state_url: baseUrlProd + "" + state_url,
    affinities_url_prod: baseUrlProd + "affinities",
    affinities_url: baseUrl + "affinities",
    affinities_search_url_prod: baseUrlProd + "affinity-search",
    affinities_search_url: baseUrl + "affinity-search",
    affinityIds_url_prod: baseUrlProd + "affinityIds",
    affinityIds_url: baseUrl + "affinityIds",
    mchannel_url_prod: baseUrlProd + "mchannelOperation",
    mchannel_url: baseUrl + "mchannelOperation",
    mailingType_url_prod: baseUrlProd + "mailingType",
    mailingType_url: baseUrl + "mailingType",
    audiences_url_prod: baseUrlProd + "audiences",
    audiences_url: baseUrl + "audiences",
    publications_url_prod: baseUrlProd + "publications",
    publications_url: baseUrl + "publications",
    publications_search_url_prod: baseUrlProd + "publications-search",
    publications_search_url: baseUrl + "publications-search",
    communication_url_prod: baseUrlProd + "communication",
    communication_url: baseUrl + "communication",
    communication_search_url_prod: baseUrlProd + "communication-search",
    communication_search_url: baseUrl + "communication-search",
    stateProvince_url_prod: baseUrlProd + "stateProvince",
    stateProvince_url: baseUrl + "stateProvince",
    companies_url_prod: baseUrlProd + "companies",
    companies_url: baseUrl + "companies",
    companies_data_url_prod: baseUrlProd + "companiesdata",
    companies_data_url: baseUrl + "companiesdata",
    companyproducts_url_prod: baseUrlProd + "companyproducts",
    companyproducts_url: baseUrl + "companyproducts",
    companiesCount_url_prod: baseUrlProd + "companiesCount",
    companiesCount_url: baseUrl + "companiesCount",
    companyImg_url_prod: baseUrlProd + "companyImg",
    companyImg_url: baseUrl + "companyImg",
    publicationsIds_url_prod: baseUrlProd + "publicationsIds",
    publicationsIds_url: baseUrl + "publicationsIds",
    get_sec_name: baseUrl + "get-sectors-name",
    get_sec_name_prod: baseUrlProd + "get-sectors-name",
    get_sectors_url: baseUrl + "get-sectors",
    get_sectors_prod_url: baseUrlProd + "get-sectors",
    all_sectors_url: baseUrl + "all-sectors",
    all_sectors_prod_url: baseUrlProd + "all-sectors",
    get_file_uploads_url: baseUrl + get_file_uploads,
    get_file_uploads_prod_url: baseUrlProd + get_file_uploads,
    download_file_uploads_url: baseUrl + download_file_uploads,
    download_file_uploads_prod_url: baseUrlProd + download_file_uploads,
    manage_reports_url: baseUrl + manage_reports,
    manage_reports_prod_url: baseUrlProd + manage_reports,
    panalist_reports_url: admin_report_url_prod + "panelists_score",
    panalist_reports_prod_url: admin_report_url_prod + "panelists_score",
    get_client_report_url: baseUrl + "get-client-report",
    get_client_report_prod_url: baseUrlProd + "get-client-report",
    download_client_report_url: baseUrl + "download-client-report-file",
    download_client_report_prod_url: baseUrlProd + "download-client-report-file",
    manage_users_url: baseUrlUser + "manageUsers",
    manage_users_prod_url: baseUrlUserProd + "manageUsers",
    addproduct_form_url: addproduct_form_url,
    viewproduct_form_url: viewproduct_form_url,
    editprouct_url: editprouct_url,
    reset_password_url: baseUrlUser + "resetPassword",
    reset_password_prod_url: baseUrlUserProd + "resetPassword",
    enable_user_url: baseUrlUser + "enableUser",
    enable_user_prod_url: baseUrlUserProd + "enableUser",
    user_role_url: baseUrlUser + "userRole",
    user_role_prod_url: baseUrlUserProd + "userRole",
    module_url: baseUrlUser + "module",
    module_prod_url: baseUrlUserProd + "module",
    get_module_url: baseUrlUser + "getModules",
    get_module_prod_url: baseUrlUserProd + "getModules",
    module_operation_url: baseUrlUser + "moduleoperations",
    module_operation_prod_url: baseUrlUserProd + "moduleoperations",
    module_operation_data_url: baseUrlUser + "moduleOperationData",
    module_operation_data_prod_url: baseUrlUserProd + "moduleOperationData",
    image_list: product_api_end + "imagelist",
    image_list_prod: product_api_end + "imagelist",
    frontend_url: frontend_url,
    frontend_url_prod: frontend_url_prod,
    manage_pdf_url: frontend_url + "managepdf/",
    manage_pdf_url_prod: frontend_url_prod + "managepdf/",
    sign_in_url: signInUrl,
    sign_in_prod_url: signInProdUrl,
    role_permission_url: rolePermissionUrl,
    role_permission_prod_url: rolePermissionProdUrl,
    domain_name: "localhost",
    domain_prod_name: ".competiscan.com",
    sign_out_url: baseUrlUser + "logout",
    sign_out_prod_url: baseUrlUserProd + "logout",
    auto_sign_out_url: baseUrlUser + "autoLogout",
    auto_sign_out_prod_url: baseUrlUserProd + "autoLogout",
    refresh_token_url: baseUrlUser + "refreshToken",
    refresh_token_prod_url: baseUrlUserProd + "refreshToken",
    clear_redis_cache_url: baseUrlUser + "deleteRedisKeys",
    clear_redis_cache_url_prod: baseUrlUserProd + "deleteRedisKeys",
    redis_keys_url: baseUrlUser + "getAllRedisKeys",
    redis_keys_url_prod: baseUrlUserProd + "getAllRedisKeys",
    redis_key_data_url: baseUrlUser + "getRedisKeyData",
    redis_key_data_url_prod: baseUrlUserProd + "getRedisKeyData",
    manage_services_url: baseUrlUser + "services",
    manage_services_prod_url: baseUrlUserProd + "services",
    manage_endpoint_url: baseUrlUser + "endpoints",
    manage_endpoint_prod_url: baseUrlUserProd + "endpoints",
    manage_service_endpoint_permission_url: baseUrlUser + "serviceEndpointPermission",
    manage_service_endpoint_permission_prod_url: baseUrlUserProd + "serviceEndpointPermission",
    product_pdf_url: product_api_end,
    product_pdf_url_prod: product_api_end + "uploadpdf",
    log_url: temprouct_url + "adminlog",
    log_url_prod: temprouct_url_prod + "/adminlog/",
    product_raw_data: temprouct_url_prod + "adminproductrawdata",
    product_raw_data_prod: temprouct_url_prod + "adminproductrawdata",
    processed_prod_url: temprouct_url + "processproduct",
    processed_prod_url_prod: temprouct_url_prod + "processproduct",
    manage_panel_url: baseUrlUser + "panel",
    manage_panel_prod_url: baseUrlUserProd + "panel",
    user_log_data_url: baseUrlUser + "userLog",
    user_log_data_prod_url: baseUrlUserProd + "userLog",
    mass_save_url: temprouct_url + "productmassupdate",
    mass_save_url_prod: temprouct_url_prod + "productmassupdate",
    direct_mail_pdf: temprouct_url + "openpdf",
    direct_mail_pdf_prod: temprouct_url_prod + "openpdf",
    base_temp_product: temprouct_url,
    base_temp_product_prod: temprouct_url_prod,
    search_form_rawdata_url: temprouct_url_prod + "adminsearchrawdata",
    search_form_rawdata_prod_url: temprouct_url_prod + "adminsearchrawdata",
    addnewproduct_url: frontend_url + "addproduct",
    addnewproduct_url_prod: frontend_url_prod + "addproduct",
    staging_contentsite_search: staging_contentsite_search,
    deduplicates_url: baseUrlSearch + "deduplicates",
    deduplicates_url_prod: baseUrlSearchProd + "deduplicates",
    deduplicates_search_url: baseUrlSearch + "deduplicate-search",
    deduplicates_search_url_prod: baseUrlSearchProd + "deduplicate-search",
    http_methods_url: baseUrlUser + "http-methods",
    http_methods_url_prod: baseUrlUserProd + "http-methods",
    db_users_url: baseUrlUser + "dbUsers",
    db_users_url_prod: baseUrlUserProd + "dbUsers",
    sync_db_users_url: baseUrlUser + "syncDbUsers",
    sync_db_users_url_prod: baseUrlUserProd + "syncDbUsers",
    log_more_info_url: baseUrlUser + "LogMoreInfo",
    log_more_info_url_prod: baseUrlUserProd + "LogMoreInfo",
    mobile_digital_url: temprouct_url + "mobile-digital",
    mobile_digital_url_prod: temprouct_url_prod + "mobile-digital",
    mobile_tempproduct_url: temprouct_url + "mobile-digital-temp-product",
    mobile_tempproduct_url_prod: temprouct_url_prod + "mobile-digital-temp-product",
    product_archieve_url: temprouct_url + "archieveproducts",
    product_archieve_url_prod: temprouct_url_prod + "archieveproducts",
    soft_delete_url: temprouct_url_prod + "productarchieve",
    soft_delete_url_prod: temprouct_url_prod + "productarchieve",
    product_restore_url: temprouct_url_prod + "productrestore",
    product_restore_url_prod: temprouct_url_prod + "productrestore",
    hard_delete_url: temprouct_url_prod + "permanentdelete",
    hard_delete_url_prod: temprouct_url_prod + "permanentdelete",
    role_product_taxonomy_url: baseUrlUser + "roleProductTaxonomy",
    role_product_taxonomy_url_prod: baseUrlUserProd + "roleProductTaxonomy",
    section_url: temprouct_url + "getallsections",
    section_url_prod: temprouct_url_prod + "getallsections",
    producer_mail: temprouct_url + "producermail",
    producer_mail_prod: temprouct_url_prod + "producermail",
    broker_mail: temprouct_url + "mortgagemail",
    broker_mail_prod: temprouct_url_prod + "mortgagemail",
    contactus_mail: temprouct_url + "contactusmail",
    contactus_mail_prod: temprouct_url_prod + "contactusmail",
    dmduplicates_url: temprouct_url + "dmduplicate",
    dmduplicates_url_prod: temprouct_url_prod + "dmduplicate",
    dmduplicates_search_url: temprouct_url + "dmduplicate-search",
    dmduplicates_search_url_prod: temprouct_url_prod + "dmduplicate-search",
    dailydtatusreport_search_url: admin_report_url_prod + "daily-status-report",
    dailydtatusreport_search_url_prod: admin_report_url_prod + "daily-status-report",
    panalist_reports_upload: admin_report_url_prod + "panelists_score_import",
    panalist_reports_upload_prod_url: admin_report_url_prod + "panelists_score_import",
    producer_panelist_report_url: admin_report_url + "producer-panelist",
    producer_panelist_report_url_prod: admin_report_url_prod + "producer-panelist",
    download_producer_panelist_report_url: admin_report_url + "producer-panelist-download",
    download_producer_panelist_report_url_prod: admin_report_url_prod + "producer-panelist-download",
    bags_directmail_import_url: admin_report_url + "bags-directmail-import", 
    bags_directmail_import_url_prod: admin_report_url_prod + "bags-directmail-import",
    bags_directmail_update_url: admin_report_url + "producer-bags-directmail-update",
    bags_directmail_update_url_prod: admin_report_url_prod + "producer-bags-directmail-update",
    company_approve_list: temprouct_url + "approvecompanylist",
    company_approve_list_prod: temprouct_url_prod + "approvecompanylist",
    dm_report: temprouct_url + "dmreport",
    dm_report_prod: temprouct_url_prod + "dmreport",
    sidebar_sorting_update_url: baseUrlUser + "update-sidebar-sorting",
    sidebar_sorting_update_prod_url: baseUrlUserProd + "update-sidebar-sorting",

    panelist_report_url: admin_report_url + "prov-brok-panelist",
    panelist_report_url_prod: admin_report_url_prod + "prov-brok-panelist",
    download_panelist_report_url: admin_report_url + "prov-brok-panelist-download",
    download_panelist_report_url_prod: admin_report_url_prod + "prov-brok-panelist-download",
    user_tracking_url: baseUrlUser + "usertracking",
    user_tracking_url_prod: baseUrlUserProd + "usertracking",
    client_list_url: baseUrlUser + "getclients",
    client_list_url_prod: baseUrlUserProd + "getclients",
    export_data_url: baseUrlUser + "exportusertracking",
    export_data_url_prod: baseUrlUserProd + "exportusertracking",
    compare_pdf: temprouct_url + "compare_pdf",
    compare_pdf_prod: temprouct_url_prod + "compare_pdf",
    pixel_report: admin_report_url + "pixel-report",
    pixel_report_prod: admin_report_url_prod + "pixel-report",
    pixel_report_download: admin_report_url + "pixel-report-download",
    pixel_report_download_prod: admin_report_url_prod + "pixel-report-download",
    annotation_tool_url: baseUrl_annotation_tool,
    annotation_tool_url_prod: baseUrl_annotation_tool_prod,
    easy_ocr_url: easy_ocr_baseurl,
    easy_ocr_baseurl_prod: easy_ocr_baseurl_prod,
    upload_file: file_upload_url,
    upload_file1: file_upload_url1,
    upload_file_prod: file_upload_url_prod,
    upload_file_prod1: file_upload_url_prod1,
    csv2_product_bucket_path: csv2_product_bucket_path,
    csv2_product_bucket_path_prod: csv2_product_bucket_path_prod,
    assign_user_url: product_api_end + "assign-user",
    assign_user_url_prod: product_api_end_prod + "assign-user",
    csv2_annotation_reviewed_url: 'https://ml-anotation-tool-uat-api.competiscan.com/v3/reviewed',
    csv2_annotation_reviewed_url_prod:  'https://ml-anotation-tool-uat-api.competiscan.com/v3/reviewed',
    merge_base_url:merge_base_url,
    merge_base_url_prod :merge_base_url,
    addproduct_form_urlv2: addproduct_form_urlv2,
    all_rawdata: temprouct_url_prod + "data/rawdata",
    all_rawdata_prod: temprouct_url_prod + "data/rawdata",
    data_sector: temprouct_url_prod + "data/sectors/",
    data_sector_prod: temprouct_url_prod + "data/sectors/",
    client_rawdata: baseUrlUserProd + "get-client-rawdata",
    client_rawdata_prod: baseUrlUserProd + "get-client-rawdata",
    all_client_users: baseUrlUserProd + "get-all-client-users",
    all_client_users_prod: baseUrlUserProd + "get-all-client-users",
    client_user: baseUrlUserProd + "client-user",
    client_user_prod: baseUrlUserProd + "client-user",
    user_permission: baseUrlUserProd + "user-permission",
    user_permission_prod: baseUrlUserProd + "user-permission",
    client_import:baseUrlUserProd + "import-user-csv",
    client_import_prod:baseUrlUserProd + "import-user-csv",
    export_client_report: baseUrlUserProd + "export-user-csv",
    export_client_report_prod: baseUrlUserProd + "export-user-csv",
    all_trend_report: trend_report_url + "trend_search",
    all_trend_report_prod:trend_report_url + "trend_search",
    trend_admin:trend_report_url+"trend_admin",
    delete_trend: trend_report_url + "delete_trend",
    delete_trend_prod: trend_report_url + "delete_trend",
    search_trend: trend_report_url + "trend_search",
    search_trend_prod: trend_report_url + "trend_search",
    edit_search_trend: trend_report_url + "edit_data",
    edit_search_trend_prod: trend_report_url + "edit_data",
}